<template>
  <div class="semantic">
    <div class="contents">
      <div class="contents-title">Contents</div>
      <ol list-padding-bottom>
        <li><a href="#AllTermsSection">Terms of Use for all Users</a></li>
        <li><a href="#StandardFansAndCretors">Standard Agreement Between Fan and Creator</a></li>
        <li><a href="#CreatorAgreement">Creator Agreement</a></li>
        <li><a href="#ComplaintPolicySection">Complaint Policy</a></li>
        <li><a href="#ReferralProgramSection">Referral Program Terms</a></li>
      </ol>
      <Spacer num="1" />
    </div>
    <div id="AllTermsSection">
      <!-- <PdfVisor v-for="(pdf, index) in pdfList" :pdf="pdf" :key="index" /> -->
      <div v-for="(pdf, index) in pdfList" :pdf="pdf" :key="index" v-html="loadHTML(pdf)"></div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Terms of Service - My Fans 1A",
  },
  components: {
    PdfVisor: () => import("../widgets/PdfVisor.vue"),
  },
  data: function() {
    return {
      pdfList: [
        "/docs/MyFans1A.com Terms-of-Service Agreement.html",
        "/docs/MyFans1A.com Standard Agreement Between Fan and Creator.html",
        "/docs/MyFans1A.com Creator Agreement.html",
        "/docs/MyFans1A.com Complaint Policy.html",
        "/docs/MyFans1A.com Referral Program Terms.html",
      ],
    };
  },
};
</script>
